<template>
  <v-text-field
    v-model="password"
    type="text"
    label="Group Code"
  ></v-text-field>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError",],
   data: () => ({
    password : null
  }),
  watch: {
    password: function (val) {
     this.password = val.toUpperCase()
      this.callbackSelect(val);
    },
  },
  methods:{
    
  },
};
</script>

<style>
</style>