<template>
  <v-container>
    <ScoreboardStudent />
  </v-container>
</template>

<script>
import ScoreboardStudent from '../../components/Scoreboard/Student/ScoreboardStudent.vue'
export default {
	components:{
		ScoreboardStudent,
	}
};
</script>