<template>
  <div>
    <div v-if="options.xaxis.categories.length != 0" class="ma-1">
      <VueApexCharts
        height="320px"
        type="line"
        :options="options"
        :series="series"
      ></VueApexCharts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  components: {
    VueApexCharts,
  },
  props: ["start", "end", "submission", "totalScore", "totalAttempt"],
  data: () => ({
    attempts: [],
    attemptsFilter: [],
    allDates: [],
    filterDates: [],
    names: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    series: [
      {
        name: "Score",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Growth Chart",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
    },
  }),
  methods: {
    dates() {
      var xRange = 10;
      //
      var submission = this.$_.clone(this.submission);
      //
      //
      var dayStart = moment(this.start).startOf("day");
      var dayEnd = moment(this.end).startOf("day");
      var dayToday = moment().startOf("day");
      //
      var dayFirst, dayLast;
      //
      var dayDiffEnd = dayEnd.diff(dayStart, "days") + 1;
      
      var dayDiffStartToday = dayToday.diff(dayStart, "days") + 1;

      dayFirst = dayStart;
      //BOC: get dayLast
      if (moment() > moment(this.end) || dayDiffEnd < xRange || dayDiffStartToday < xRange) {
        //BOC: past event
        dayLast = dayEnd;
        //EOC
      } else {
        //BOC: ongoing event
        dayLast = dayToday;
        //EOC
      }
      //EOC

      //BOC: generate dates
      var dates = [];
      for (var d = 0; ; d++) {
        var newDay = dayFirst.clone().add(d, "days");
        if (newDay > dayLast) break;
        this.filterDates.push(newDay.format("DD/MM"));
        dates.push({
          date: newDay.format("DD/MM"),
          fullDate: newDay.toISOString(),
        });
      }
      this.allDates = dates;
      //EOC
      var filterSubmissionByQuestion = [];
       if (submission.length == 0) {
        filterSubmissionByQuestion.push({
          date: this.filterDates[0],
          score: this.totalScore,
        });
      } else {
      for (var j = 0, k = this.allDates.length; j < k; j++) {
        for (var p = 0, q = submission.length; p < q; p++) {
          if (
            this.allDates[j].date ==
            moment(submission[p].timestampCreated).format("DD/MM")
          ) {
            this.attempts.push({
              questionKey: submission[p].questionKey,
              date: this.allDates[j],
              fullDate: this.allDates[j].fullDate,
              score: submission[p].score,
            });
          }
        }
      }
      this.attempts.sort((a, b) => (a.fullDate < b.fullDate ? 1 : -1));
      var filterSubmission = [];
      var result = [];
      var allDates = this.allDates;
      for (var a = 0, b = allDates.length; a < b; a++) {
        result = this.attempts.filter(function (entry) {
          return entry.fullDate <= allDates[a].fullDate;
        });
        if (result.length != 0) {
          filterSubmission.push(result);
        }
      }
      for (var x = 0, y = filterSubmission.length; x < y; x++) {
        if (filterSubmission[x]) {
          filterSubmission[x].sort((a, b) =>
            a.questionKey < b.questionKey ? 1 : -1
          );
          for (var n = 0, m = filterSubmission[x].length; n < m - 1; n++) {
            if (
              filterSubmission[x][n].questionKey ==
              filterSubmission[x][n + 1].questionKey
            ) {
              if (
                filterSubmission[x][n].score > filterSubmission[x][n + 1].score
              ) {
                filterSubmission[x].splice(n + 1, 1);
                n--;
                m--;
              } else {
                filterSubmission[x].splice(n, 1);
                n--;
                m--;
              }
            }
          }
        }
      }
      for (var s = 0, t = filterSubmission.length; s < t; s++) {
        filterSubmission[s].sort((a, b) => (a.fullDate < b.fullDate ? 1 : -1));
        var score = 0;
        for (var w = 0, z = filterSubmission[s].length; w < z; w++) {
          score = score + filterSubmission[s][w].score;
        }
        if (
          filterSubmissionByQuestion.length > 0 &&
          !filterSubmissionByQuestion[filterSubmissionByQuestion.length - 1]
            .date == filterSubmission[s][0].date.date
        ) {
          filterSubmissionByQuestion.push({
            date: filterSubmission[s][0].date.date,
            score: score,
          });
        } else {
          filterSubmissionByQuestion.push({
            date: filterSubmission[s][0].date.date,
            score: score,
          });
        }
      }
      }
      this.joinWithChart(filterSubmissionByQuestion,submission.length);
    },
    joinWithChart(filterAttempt,submissionLength) {
      var xRange = 10;
      var dayStart = moment(this.start).startOf("day");
      var dayEnd = moment(this.end).startOf("day");
      var dayToday = moment().startOf("day");
      var dayDiffStartToday = dayToday.diff(dayStart, "days") + 1;
      var dayDiffEnd = dayEnd.diff(dayStart, "days") + 1;
 
      //EOC
      var data = [];
      var score = 0;
      for (var x = 0, y = this.allDates.length; x < y; x++) {
        for (var n = 0, m = filterAttempt.length; n < m; n++) {
          if (this.allDates[x].date == filterAttempt[n].date) {
            score = filterAttempt[n].score;
          }
        }
        data.push(score);

         
      }
       if (this.totalAttempt > submissionLength) {
        for (var t = 0, u = data.length; t < u; t++) {
          if (data[t] != 0) {
            if (data[t] == data[t + 1] && data[t] == data[data.length - 1]) {
              data[t] = this.totalScore;
            }
          }
        }
      }
      var startDate;
      var endDate;
      if (dayDiffEnd >= xRange && dayToday < dayEnd && dayDiffStartToday < xRange) {
        startDate =startDate = dayStart.clone().format("DD/MM");
        endDate = moment().add(xRange, "days").format("DD/MM");
      } else if (dayDiffEnd >= xRange && dayToday < dayEnd && dayDiffStartToday >= xRange) {
        startDate = moment().subtract(xRange, "days").format("DD/MM");
        endDate = dayToday.clone().format("DD/MM");
      } else if (dayDiffEnd < xRange && dayToday < dayEnd) {
        endDate = dayEnd.clone().format("DD/MM");
        startDate = dayStart.clone().format("DD/MM");
      } else if (dayDiffEnd >= xRange && dayToday > dayEnd) {
        endDate = dayEnd.clone().format("DD/MM");
        startDate = dayEnd.clone().subtract(xRange, "days").format("DD/MM");
      }

      let startIndex = this.filterDates.indexOf(startDate);
      let endIndex = this.filterDates.indexOf(endDate);
      var finalData = this.$_.cloneDeep(data.slice(startIndex, endIndex));
      var finalAllDates = this.filterDates.slice(startIndex, endIndex);
      if (dayDiffEnd < xRange) {
        this.series[0].data = data;
        this.options.xaxis.categories = this.$_.cloneDeep(this.filterDates);
      } else {
        this.series[0].data = finalData;
        this.options.xaxis.categories = this.$_.cloneDeep(finalAllDates);
      }
    },
  },
  beforeMount() {
    this.dates();
  },
};
</script>