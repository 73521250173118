var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"0","max-width":"800","outlined":""}},[_c('v-card-text',[(!_vm.isLoading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.no")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.name.chapter")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.name.question")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.attempt")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.name.submissions")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.stars")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("model.prop.score")))])])]),_vm._l((_vm.records),function(question,index,a){return _c('tbody',{key:index},[_c('tr',[_c('td',[_vm._v(_vm._s(a + 1))]),_c('td',[_vm._v(_vm._s(question.chapter_name))]),_c('td',[_vm._v(_vm._s(question.name))]),(question.EventRecord[0])?_c('td',[_vm._v(" "+_vm._s(question.EventRecord[0].attempt)+" ")]):_c('td',[_vm._v("0")]),(question.EventRecord[0])?_c('td',{on:{"click":function($event){return _vm.viewSubmission(question.chapter_key, question.key)}}},[_vm._v(" "+_vm._s(question.EventRecord[0].submission)+" "),(question.EventRecord[0].submission)?_c('span',[(
                      _vm.selectedChapterKey == question.chapter_key &&
                      _vm.selectedQuestionKey == question.key
                    )?_c('v-icon',[_vm._v("mdi-menu-up")]):_c('v-icon',[_vm._v("mdi-menu-down")])],1):_vm._e()]):_c('td',{on:{"click":function($event){return _vm.viewSubmission(question.chapter_key, question.key)}}},[_vm._v(" 0 ")]),(question.EventRecord[0])?_c('td',[_vm._v(" "+_vm._s(question.EventRecord[0].topStar)+" ★ ")]):_c('td',[_vm._v("0 ★")]),(question.EventRecord[0])?_c('td',[_vm._v(" "+_vm._s(question.EventRecord[0].topScore)+" ")]):_c('td',[_vm._v("0")])]),_vm._l((_vm.submissions &&
              _vm.selectedChapterKey == question.chapter_key &&
              _vm.selectedQuestionKey == question.key
                ? _vm.submissions
                : null),function(submission,index){return _c('tr',{key:index,staticClass:"submission"},[_c('td'),_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(submission.timestampCreated).format( "DD/MM/YYYY hh:mm a" ))+" ")]),_c('td'),_c('td',[_vm._v(_vm._s(submission.submission))]),_c('td',[_vm._v(_vm._s(submission.star)+" ★")]),_c('td',[_vm._v(_vm._s(submission.score))])])}),(
                _vm.submissionApi.isLoading &&
                _vm.selectedChapterKey == question.chapter_key &&
                _vm.selectedQuestionKey == question.key
              )?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_c('ALoader',{attrs:{"isLoading":true}})],1)]):_vm._e()],2)})]},proxy:true}],null,false,1224916595)}):_c('ALoader',{attrs:{"isLoading":true}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }