<template>
  <div>
    <v-card elevation="0" class="mx-auto" max-width="800" outlined>
      <!-- <v-card-text v-if="playerApi.isLoading">
          <ASkeletonLoader type="table" width="100%" />
        </v-card-text> -->
      <v-card-text>
        <v-simple-table v-if="!isLoading">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("model.prop.no") }}</th>
                <th class="text-left">{{ $t("model.name.chapter") }}</th>
                <th class="text-left">{{ $t("model.name.question") }}</th>
                <th class="text-left">{{ $t("model.prop.attempt") }}</th>
                <th class="text-left">{{ $t("model.name.submissions") }}</th>
                <th class="text-left">{{ $t("model.prop.stars") }}</th>
                <th class="text-left">{{ $t("model.prop.score") }}</th>
              </tr>
            </thead>
            <tbody v-for="(question, index, a) in records" :key="index">
              <tr>
                <td>{{ a + 1 }}</td>
                <td>{{ question.chapter_name }}</td>
                <td>{{ question.name }}</td>
                <td v-if="question.EventRecord[0]">
                  {{ question.EventRecord[0].attempt }}
                </td>
                <td v-else>0</td>
                <td
                  v-if="question.EventRecord[0]"
                  @click="viewSubmission(question.chapter_key, question.key)"
                >
                  {{ question.EventRecord[0].submission }}
                  <span v-if="question.EventRecord[0].submission"
                    ><v-icon
                      v-if="
                        selectedChapterKey == question.chapter_key &&
                        selectedQuestionKey == question.key
                      "
                      >mdi-menu-up</v-icon
                    >
                    <v-icon v-else>mdi-menu-down</v-icon>
                  </span>
                </td>

                <td
                  v-else
                  @click="viewSubmission(question.chapter_key, question.key)"
                >
                  0
                </td>

                <td v-if="question.EventRecord[0]">
                  {{ question.EventRecord[0].topStar }} ★
                </td>
                <td v-else>0 ★</td>
                <td v-if="question.EventRecord[0]">
                  {{ question.EventRecord[0].topScore }}
                </td>
                <td v-else>0</td>
              </tr>
              <tr
                class="submission"
                v-for="(submission, index) in submissions &&
                selectedChapterKey == question.chapter_key &&
                selectedQuestionKey == question.key
                  ? submissions
                  : null"
                :key="index"
              >
                <td></td>
                <td></td>
                <td>
                  {{
                    $moment(submission.timestampCreated).format(
                      "DD/MM/YYYY hh:mm a"
                    )
                  }}
                </td>
                <td></td>
                <td>{{ submission.submission }}</td>
                <td>{{ submission.star }} ★</td>
                <td>{{ submission.score }}</td>
              </tr>
              <tr
                v-if="
                  submissionApi.isLoading &&
                  selectedChapterKey == question.chapter_key &&
                  selectedQuestionKey == question.key
                "
              >
                <td colspan="7" class="text-center">
                  <ALoader :isLoading="true" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <ALoader v-else :isLoading="true" />
      </v-card-text>
    </v-card>
    <!-- <div
        v-if="data.totalAttempt - submissionLog.length > 1 && data.Record"
        class="text-caption text-right pt-2"
      >
        {{ data.totalAttempt - submissionLog.length
        }}{{ $t("view.PageMainEventScoreboardStudent.missing_attempt") }}
      </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: mapState({
    //
  }),
  props: ["questions","event"],
  data: () => ({
    items: [],
    selectedSchoolYear: null,
    selectedQuestionKey: null,
    selectedChapterKey: null,
    isLoading: false,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    QuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    submissionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    chapters: [],
    topics: [],
    checkpoint: [],
    schoolYears: [],
    records: [],
    submissions: [],
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
      var value = this.selectedItem;
      var question = this.items.find(function (entry) {
        return entry.key == value.key;
      });
      this.selectedQuestion["question"] = question.question_code.question;
    };
    this.submissionApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/submission/player/" +
      this.$route.params.mochiId +
      "/getByEventTopic";
    this.submissionApi.callbackReset = () => {
      this.submissionApi.isLoading = true;
      this.submissionApi.isError = false;
    };
    this.submissionApi.callbackError = (e) => {
      this.submissionApi.isLoading = false;
      this.submissionApi.isError = true;
      this.submissionApi.error = e;
    };
    this.submissionApi.callbackSuccess = (resp) => {
      this.submissionApi.isLoading = false;
      this.submissions = resp.Submission;
    };
  },
  mounted() {
    this.isLoading = true;
    this.checkpoint = this.questions[0].SchoolYearTopic;

    this.chapters = [
      ...new Map(
        this.checkpoint.map((item) => [item["chapterKey"], item])
      ).values(),
    ];
    this.getQuestions();
  },
  methods: {
    viewSubmission(chapterKey, questionKey) {
      this.submissions = [];
      if (
        this.selectedChapterKey &&
        this.selectedChapterKey == chapterKey &&
        this.selectedQuestionKey &&
        this.selectedQuestionKey == questionKey
      ) {
        this.selectedChapterKey = null;
        this.selectedQuestionKey = null;
      } else {
        this.selectedChapterKey = chapterKey;
        this.selectedQuestionKey = questionKey;
        this.submissionApi.params = this.$_.clone({
          chapterKey: chapterKey,
          questionKey: questionKey,
          eventId: this.event.id,
        });
        this.$api.fetch(this.submissionApi);
      }
    },
    selectSchoolYear(year) {
      this.selectedSchoolYear = year;
      this.isLoading = true;
      this.getSchoolYear();
    },

    async getQuestions() {
      for (let i = 0; i < this.chapters.length; i++) {
        await this.questionApiCall(this.chapters[i].chapterKey);
      }
    },
    async questionApiCall(chapterKey) {
      this.QuestionApi.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        chapterKey +
        "/questions?lang="+this.$_getLocale();

      this.QuestionApi.callbackReset = () => {
        this.QuestionApi.isLoading = true;
        this.QuestionApi.isError = false;
      };

      this.QuestionApi.callbackError = (e) => {
        this.QuestionApi.isLoading = false;
        this.QuestionApi.isError = true;
        this.QuestionApi.error = e;
      };
      this.QuestionApi.callbackSuccess = (resp) => {
        this.checkpoint.forEach((q) => {
          let topic = resp.find((element) => element.key == q.questionKey);
          if (topic) {
            topic.chapter_key = q.chapterKey;
            this.topics.push(topic);
          }
        });
        this.QuestionApi.isLoading = false;
        if (this.topics.length == this.checkpoint.length) {
          this.records = this.$_.merge(
            this.$_.keyBy(this.topics, "key"),
            this.$_.keyBy(this.checkpoint, "questionKey")
          );
          this.isLoading = false;
        }
      };
      this.QuestionApi.method = "GET";

      this.QuestionApi.params = { lang: this.$_getLocale() };
      await this.$api.fetch(this.QuestionApi);
    },
  },
};
</script>
<style>
.submission {
  background-color: #e3f2fd;
}
.submission:hover {
  background-color: #bbdefb !important;
}
</style>