<template>
  <v-row justify="center">
    <v-btn v-if="auth.Session && auth.Account.type == 'moderator'" :to="{ name: 'PageMainEventScoreboard', params: { eventCode: code } }"  text color="primary" class="view-event text-right">
       {{buttonText}}
    </v-btn>
    <v-btn v-else @click.stop="dialog = true" text color="primary" class="view-event text-right">
      {{buttonText}}
      <v-icon right dark> mdi-lock-outline </v-icon>
    </v-btn>
  
    <v-dialog v-model="dialog" max-width="290">
      <v-card :loading="api.isLoading">
        <v-card-title class="text-h6">
          Enter Code
          <v-spacer></v-spacer>
          <v-btn  icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" lazy-validation @submit.prevent="validate">
          <v-card-text>
            <FormInput :callbackSelect="callbckSelect" :password="password" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text :to="{ name: $route.params.studentId ? 'PageMainEventScoreboardStudent': pageName, params: { eventCode: code }, query: {c: formPassword} }">
             Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormInput from "@/components/Event/Password/FormInput";
 import { mapState } from 'vuex'
export default {
  computed: mapState({
      auth: state => state.auth.data,
    }),
  data() {
    return {
      dialog: false,
      formPassword: "",
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
    };
  },
  props: ["code", "password", "callbackSuccess","buttonText","pageName"],
  components: {
    FormInput,
  },
  methods: {
    callbckSelect(password) {
      this.formPassword = password;
    },
  },
  mounted(){
    this.formPassword = this.password
  }
};
</script>

<style>
.view-event::before {
  background-color: transparent !important;
}
</style>