<template>
  <div class="ma-1">
    <VueApexCharts
      height="320px"
      type="heatmap"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  components: {
    VueApexCharts,
  },
  props: [
    "start", 
    "end", 
    "submission",
  ],
  data: () => ({
    attempts: [],
    attemptsFilter: [],
    allDates: [],
    names: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    options: {
      chart: {
        height: 330,
        type: "heatmap",
        toolbar: {
          show: false,
        },
        animations:{
          enabled:false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "Submissions Chart",
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
    },
    series: [],
  }),
  methods: {
    dates() {
      var xRange = 10;
      //
      var submission = this.$_.clone(this.submission)
      //
      submission.sort((a, b) =>
        a.timestampCreated < b.timestampCreated ? 1 : -1
      );
      //
      var dayStart = moment(this.start).startOf('day');
      var dayEnd = moment(this.end).startOf('day');
      var dayToday = moment().startOf('day');
      //
      var dayFirst, dayLast;
      //
      var dayDiffEnd = dayEnd.diff(dayStart,"days") + 1;
      var dayDiffToday = dayToday.diff(dayStart,"days") + 1;
      //
      var dayGapToday = 0
      if(dayDiffToday < xRange) dayGapToday = xRange - dayDiffToday
      //
      if(submission.length > 0) {
        var dayLastSubmission = moment(submission[0].timestampCreated).startOf('day');
        var dayDiffLastSubmission = dayLastSubmission.diff(dayStart,"days") + 1;
        var dayGapLastSubmission = 0
        if(dayDiffLastSubmission < xRange) dayGapLastSubmission = xRange - dayDiffLastSubmission
      }
      //BOC: get dayFirst and dayLast
      if(dayDiffEnd > xRange) {
        if(moment() > moment(this.end)) {
          //BOC: past event
          if(submission.length == 0) {
            // console.log("scenario: many days, past, no submission")
            dayLast = dayEnd
          }
          else {
            // console.log("scenario: many days, past, with submission")
            dayLast = dayLastSubmission.clone().add(dayGapLastSubmission,"days")
          }
          //EOC
        }
        else { 
          //BOC: ongoing event
          if(submission.length == 0) {
            // console.log("scenario: many days, ongoing, no submission")
            dayLast = dayToday.clone().add(dayGapToday,"days")
          }
          else {
            // console.log("scenario: many days, ongoing, with submission")
            dayLast = dayLastSubmission.clone().add(dayGapLastSubmission,"days")
          }
          //EOC
        }
        dayFirst = dayLast.clone().subtract(xRange-1,'days')
      }
      else {
        dayFirst = dayStart;
        dayLast = dayEnd;
      }
      //EOC
      // var start = moment(this.start).subtract(1, "days");
      // var end = moment(this.end).add(1, "days");
      // var now = moment().add(1, "days");
      //
      // var firstDate;
      // var lastDate;
      // var dateCount = end.diff(start, "days");
      // if (dateCount > 8 && now < end) {
      //   if (submission.length != 0) {
      //     lastDate = moment(submission[0].timestampCreated).add(1, "days");
      //     if (
      //       moment(submission[0].timestampCreated).subtract(7, "days") <
      //       start
      //     ) {
      //       firstDate = start;
      //       console.log(start,   moment(submission[0].timestampCreated).subtract(7, "days"))
      //     } else {
      //       firstDate = moment(submission[0].timestampCreated).subtract(
      //         7,
      //         "days"
      //       );
      //     }
      //   } else {
      //     firstDate = moment().subtract(7, "days");
      //     lastDate = now;
      //   }
      // } else if (dateCount > 8 && now > end) {
      //   if (submission.length != 0) {
      //     lastDate = moment(submission[0].timestampCreated).add(1, "days");
      //     if (
      //       moment(submission[0].timestampCreated).subtract(7, "days") >
      //       start
      //     ) {
      //       firstDate = moment(end).subtract(8, "days");
      //     } else {
      //       firstDate = moment(submission[0].timestampCreated).subtract(
      //         7,
      //         "days"
      //       );
      //     }
      //   } else {
      //   lastDate = end;
      //   firstDate = moment(end).subtract(8, "days");
      //   }
      // } else if (dateCount <= 8) {
      //   firstDate = start;
      //   lastDate = end;
      // }
      // while (firstDate.add(1, "days").diff(lastDate) < 0) {
      //   this.allDates.push(firstDate.format("DD/MM"));
      // }
      //BOC: generate dates
      var dates = []
      for(var d=0;d<xRange;d++) {
        var newDay = dayFirst.clone().add(d,"days")
        if(newDay > dayLast) break
        dates.push(newDay.format("DD/MM"))
      }
      this.allDates = dates
      //EOC
      this.names.reverse();

      for (var j = 0, k = this.allDates.length; j < k; j++) {
        for (var n = 0, m = submission.length; n < m; n++) {
          if (
            this.allDates[j] ==
            moment(submission[n].timestampCreated).format("DD/MM")
          ) {
            this.attempts.push({
              date: this.allDates[j],
              fullDate: submission[n].timestampCreated,
              time: moment(submission[n].timestampCreated).format("HH:00"),
              attempts: 1,
            });
          }
        }
      }

      var temp_attempts = this.attempts;
      var temp_names = this.names;
      for (var i = 0, l = this.names.length; i < l; i++) {
        var data = [];
        this.allDates.forEach(function (date) {
          var attempt = 0;

          temp_attempts.forEach(function (item) {
            if (item.date == date && item.time == temp_names[i]) {
              attempt += item.attempts;
            }
          });
          data.push(attempt);
        });

        this.series.push({
          name: temp_names[i],
          data: data,
        });
        this.options.xaxis.categories = this.$_.cloneDeep(this.allDates);
      }
    },
  },
  beforeMount() {
    this.dates();
  },
};
</script>